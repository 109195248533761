.cart ul {
	margin: 0;
	padding: 0;

	list-style: none;
}

.cart-list__item {
	position: relative;

	margin-bottom: 1em;
	padding-bottom: 1em;

	border-bottom: 1px solid lighten($color-black, 80%);
}

.cart-list__item:last-child {
	border-bottom: 2px solid lighten($color-black, 60%);
}

.cart-list-item__remove {
	float: left;
	margin-top: -3px;
	margin-right: 1em;
}

.cart-list-item__qty {
	margin-bottom: 0;
}

.cart-list-item__total {
	position: absolute;
	right: 0;
	bottom: 0;

	margin-bottom: 1em;

	color: $color-price;
}

.cart__total-caption {
	float: left;

	margin-bottom: 0;

	font-weight: bold;
}

.cart__total-sum {
	float: right;

	margin-bottom: 0;

	color: $color-price;
	font-size: 1.15em;
	font-weight: bold;
	text-align: right;
}

.cart__total-conditions {
	clear: both;

	display: block;

	color: $color-text--lighter;
}

.mandate-acceptance {
	padding: 1em;

	background-color: lighten($color-info, 35%);
	border-radius: 3px;

	color: $color-primary;
	font-size: 0.9em;
	line-height: 1.5em;
}

@media only screen and (min-width: $break-md) {
	.cart--no-summary {
		display: block;
	}

	.cart--no-summary > section {
		width: 100%;
	}
}
