/**
 * ********** Media Object **********
 */

.media {
	margin-bottom: 2em;
}

.media:last-of-type {
	margin-bottom: 0;
}

.media,
.media__body {
	overflow: hidden;
}

.media__meta {
	float: left;

	margin-right: 30px;
}

.media__meta img {
	vertical-align: top;
}

.media h3 {
	margin-top: 0;
	margin-bottom: 0.5em;
}

.media__body p:last-child {
	margin-bottom: 0;
}

.media--flipped .media__meta {
	float: right;

	margin-right: 0px;
	margin-left: 2%;
}
