/**
 * ********** Helpers **********
 */

.browsehappy {
	padding: 0.25em;

	background-color: #FFF666;
	
	color: #222222;
	text-align: center;
}

.browsehappy a {
	color: #222222;
}

.sr-text {
	position: absolute;

	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;

	border: 0;
}

.sr-text--focusable:focus,
.sr-text--focusable:active {
	position: static;
	z-index: 100000;

	display: block;
	width: auto;
	height: auto;
	overflow: visible;
}

.sm-visible,
.md-visible,
.lg-visible,
.xl-visible {
	display: none;
}
@media only screen and (min-width: $break-sm) {
	.sm-visible {
		display: block;
	}

	.sm-hidden {
		display: none;
	}
}

@media only screen and (min-width: $break-md) {
	.md-visible {
		display: block;
	}

	.md-hidden {
		display: none;
	}
}

@media only screen and (min-width: $break-lg) {
	.lg-visible {
		display: block;
	}

	.lg-hidden {
		display: none;
	}
}

@media only screen and (min-width: $break-xl) {
	.xl-visible {
		display: block;
	}

	.xl-hidden {
		display: none;
	}
}
