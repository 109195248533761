/**
 * ********** Text **********
 */

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;

	line-height: 1.35em;
}

h1,
.h1 {
	margin-bottom: 1.25em; 

	color: $color-primary;
	font-size: 1.75em;
	font-weight: bold;
}

h1::before,
.h1::before {
	background: $color-primary;
}

h2,
.h2 {
	margin-top: 1.25em;
	margin-bottom: 0.75em; 

	font-size: 1.35em;
	font-weight: bold;
}

h3,
.h3 {
	margin-top: 1em;
	margin-bottom: 1.25em; 

	font-size: 1.15em;
	font-weight: bold;
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
	font-size: 1.05em;
	font-weight: bold;
}

h1,
.company-name {
	margin-top: 0;
	margin-bottom: 1.25em;
	padding-top: 0.5em;

	font-weight: bold;
	letter-spacing: 3px;
	text-transform: uppercase;
	line-height: 1.35em;
}

h1::before {
	position: relative;
	top: -0.5em;

	content: '';
	display: block;
	width: 60px;
	height: 0.15em;

	background: $color-primary;
}

p,
.p {
	margin: 0 0 1em;;
}

mark {
	background: #FFFF00;
	color: #000000
}

abbr {
	cursor: help;
}

abbr[title] {
	border-bottom: 1px dotted;
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

@media only screen and (min-width: $break-md) {
	h1,
	.h1 {
		font-size: 2em;
	}

	h2,
	.h2 {
		font-size: 1.65em;
	}

	h3,
	.h3 {
		font-size: 1.25em;
	}

	h4,
	h5,
	h6,
	.h4,
	.h5,
	.h6 {
		font-size: 1.15em;
	}
}
