/**
 * ********** Form **********
 */

form {
	margin-bottom: 1em;
}

legend {
	display: none;
}

fieldset {
	margin: 0;
	padding: 0;

	border: none;
}

fieldset:last-of-type {
	margin-bottom: 1em;
}

label {
	display: inline-block;
	width: auto;

	color: $color-text--lighter;
}

input,
textarea,
select {
	display: block;
	width: 100%;
	box-sizing: border-box;

	border-radius: 0;
}

input,
select {
	padding: 0.35em 0.5em;

	border: 1px solid $color-text--lighter;
	box-shadow: inset 0 1px 3px lighten($color-black, 80%);
}

select {
	min-width: 7em;
	max-width: 10em;

	// -webkit-appearance: none;
	// -moz-appearance: none;
	// -ms-appearance: none;
	// appearance: none;
}

select::-ms-expand {
	// display: none;
}

textarea {
	max-height: 320px;

	resize: vertical;
}

// .form-validated input:valid {
// 	border: 2px solid $color-positive;
// }

.form-validated input:invalid {
	border: 2px solid $color-negative;
}

.form-assistive {
	display: block;
	margin-top: 0.35em;

	color: $color-text--lighter;
	font-size: 0.75em;
	line-height: 1.25em;
}

.form-assistive--invalid {
	display: none;

	color: $color-negative;
}

.form-validated .form-assistive--invalid {
	display: block;
}

.form-validated input:valid ~ .form-assistive--invalid {
	display: none;
}

.StripeElement--invalid ~ .form-assistive {
	display: none;
}

#checkout-form {
	max-width: 540px;
}

#checkout-form button[data-loading]:disabled::after {
	position: relative;
	top: 5px;

	content: '';
	display: inline-block;
	width: 1em;
	height: 1em;
	margin-left: 0.75em;

	border: 2px solid #444444;
	border-radius: 50%;
	border-color: transparent #444444 #444444 #444444;
	animation: spin 1.2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

[type="checkbox"],
[type="checkbox"] + label {
	display: inline-block;
	width: auto;

	cursor: pointer;
}

[type="checkbox"] {
	position: absolute;

	width: 0;
	height: 0;

	appearance: none;
	opacity: 0;
}

[type="checkbox"] + label {
	position: relative;

	margin-left: 35px;
}

[type="checkbox"] + label::before {
	position: absolute;
	top: 7px;
	left: -35px;

	content: '';
	display: inline-block;
	width: 1em;
	height: 1em;

	border: 1px solid $color-text--lighter;
	box-shadow: inset 0 1px 3px lighten($color-black, 80%);

	line-height: 1.1em;
	text-align: center;
}

[type="checkbox"]:checked + label::before {
	content: '\2713';
}

[type="radio"],
[type="radio"] + label {
	display: inline-block;
	width: auto;

	cursor: pointer;
}

[type="radio"] {
	position: absolute;

	width: 0;
	height: 0;

	appearance: none;
	opacity: 0;
}

[type="radio"] + label {
	position: relative;

	margin-left: 35px;
}

[type="radio"]:first-child:checked + label {
	font-weight: bold;
}

[type="radio"] + label::before {
	position: absolute;
	top: 5px;
	left: -30px;

	content: '';
	display: inline-block;
	width: 0.35em;
	height: 0.35em;

	background: lighten($color-secondary, 40%);
	border: 0.35em solid lighten($color-secondary, 40%);
	border-radius: 50%;
	box-shadow: 0 0 0 2px lighten($color-secondary, 20%);
}

[type="radio"]:hover + label::before {
	background: lighten($color-primary, 30%);
}

[type="radio"]:checked + label::before {
	background: $color-primary;
}
