/**
 * ********** Image **********
 */

img {
	/** Responsive Image */
	max-width: 100% !important;
	height: auto;
}

.rounded {
	position: relative;

	display: block;
	width: 200px;
	height: 200px;
	margin: 0 auto;
	overflow: hidden;

	border-radius: 50%;
	box-shadow: 0 10px 7px $color-shadow;
	transition: transform 0.25s ease;
}

.rounded:hover {
	transform: scale(1.05);
}

.rounded img {
	position: absolute;
	top: 50%;
	left: 50%;

	display: block;
	max-width: auto !important;

	transform: translate(-50%, -50%);
}
