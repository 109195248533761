$background-lighten: 35%;
$color-darken: 30%;

.message,
.message--info {
	display: block;
	padding: 1em;

	background-color: lighten($color-info, $background-lighten);
	border: 1px solid $color-info;
	border-radius: 0.25em;

	color: darken($color-info, $color-darken);
}

.message--positive {
	background-color: lighten($color-positive, $background-lighten);
	border-color: $color-positive;

	color: darken($color-positive, $color-darken);
}

.message--negative {
	background-color: lighten($color-negative, $background-lighten);
	border-color: $color-negative;

	color: darken($color-negative, $color-darken);
}

.message--warning {
	background-color: lighten($color-warning, 50%);
	border-color: $color-warning;

	color: darken($color-warning, $color-darken);
}
