#card-element,
#iban-element {
	padding: 0.35em 0.5em;

	background-color: #ffffff;
	border: 1px solid $color-text--lighter;
	box-shadow: inset 0 1px 3px lighten($color-black, 80%);
}

#card-element.StripeElement--invalid,
#iban-element.StripeElement--invalid {
	border: 2px solid $color-negative;
}

#card-errors,
#iban-errors {
	font-size: 0.75em;
	color: $color-negative;
}
