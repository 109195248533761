/**
 * ********** Variables **********
 */

$color-white: #ffffff;
$color-black: #000000;

$color-primary:    #3f4359;
$color-secondary:  #697095;
$color-price:      #c0392b;
$color-price--light: #16a085; // #009432
$color-background: #eaeaea;
$color-shadow: #cccccc;

$color-info:     #52b3d9;
$color-positive: #26a65b;
$color-negative: #c4262e;
$color-warning:  #856404;

$color-text:    #343434;
$color-text--i: #ffffff;
$color-text--lighter: #666666;

$font-primary:   'Helvetica Neue', Helvetica, Arial;
// $font-secondary: 'Helvetica Neue', Helvetica, Arial;
// $font-fallback:  'WebFontName Local', $font-secondary, sans-serif;
// $font-stack:     $font-primary, $font-secondary, sans-serif;
$font-fallback: $font-primary, sans-serif;
$font-stack: $font-primary, sans-serif;

$width-site: 1280px;
$module-padding: 2.5rem;

$grid-columns: 5;
$grid-gutter: 0.5em;
$row-columns: 12;
$row-gutter: 0.75em;

$break-sm:  576px;
$break-md:  768px;
$break-lg:  960px;
$break-xl: 1200px;
