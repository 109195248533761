.back-link {
	display: block;

	padding: 0 0 1.5em;	

	font-size: 0.9em;
}

.back-link:link,
.back-link:visited {
	color: $color-text--lighter;
}

.back-link:focus,
.back-link:hover,
.back-link:active {
	color: $color-text;
}
