/**
 * ********** List **********
 */

.simple {
	margin: 0;
	padding: 0;

	list-style: none;
}

dl {
	margin: 0;
	margin-bottom: 1em;
}

dt {
	margin-bottom: 0.25em;

	font-weight: bold;
}

dd {
	margin: 0;
	margin-bottom: 1.5em;
	padding: 0;
}
