/**
 * ********** Button **********
 */

button,
.btn,
a.btn {
	display: inline-block;
	min-width: 100px;
	padding: 0.5em 1em;

	background-color: lighten($color-primary, 55%);
	border: none;
	border-radius: 3px;
	color: $color-primary;
	cursor: pointer;

	font-size: 1.05em;
	text-align: center;
	text-decoration: none;
}

button,
[type="submit"],
.btn--primary,
a.btn--primary {
	@extend .btn;

	background-color: $color-primary;

	color: lighten($color-primary, 70%);
}

.btn--secondary,
a.btn--secondary {
	background-color: $color-secondary;

	color: lighten($color-secondary, 50%);
}

.btn--special,
a.btn--special {
	padding: 0.5em 2em;

	background: rgba(255, 255, 255, 0.75);
	border: 3px solid $color-primary;
	box-shadow: 4px 4px 0px $color-primary;

	font-weight: bold;
	letter-spacing: 3px;
	text-transform: uppercase;
}

.btn--small,
a.btn--small {
	min-width: auto;
	padding: 0.15em 0.5em;

	font-size: 1em;
}


[disabled] {
	background: lighten($color-black, 80%);

	color: lighten($color-black, 50%);
}