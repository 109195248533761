/**
 * ********** Base **********
 */

html, body {
	margin: 0;
	padding: 0;

	background: $color-background;

	color: $color-text;
	font-family: $font-fallback;
	font-size: 1em;
	line-height: 1.625em;
}

// .no-js body,
// .fonts-loaded body {
// 	font-family: $font-stack;
// 	font-weight: lighter;
// }

iframe {
	display: block;
	margin: 0 auto;

	border: 0;
}
