.tabs__button {
	min-width: 0;
	padding: 0.5em 0.5em 0.15em;

	background: none;
	border-radius: 0;

	color: $color-secondary;
	font-size: 0.85em;	
	text-align: left;
}

.tabs__button--active {
	color: $color-primary;
	box-shadow: 0 2px 0 $color-primary;
}

.tabs__button--active::after {

}

.tabs .tabs__list {
	margin-bottom: 0.5em;

	border-bottom: 2px solid lighten($color-secondary, 25%);
}

.tabs__list li {
	display: inline-block;
}

.tab {
	display: none;
}

.tab--active {
	display: block;
}
