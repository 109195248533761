/**
 * ********** Table **********
 */

table {
	max-width: 100%;
	width: 100%;
	margin: 1em 0;

	border-collapse: collapse;

	text-align: left;
}

table caption {
	display: none;
}

table th {
	background-color: darken($color-background, 25%);
}

table th,
table td {
	padding: 0.35em;
}

table tr:nth-child(even) {
	background-color: darken($color-background, 10%);
}
