.video {
	position: relative;

	margin: 0 auto;
	padding-top: 55%;
}

.video iframe {
	position: absolute;
	top: 0;
	left: 50%;

	width: 100%;
	height: 100%;

	border-radius: 3px;
	transform: translateX(-50%);
}
