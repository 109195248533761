/**
 * ********** Layout **********
 */

#site-header {
	position: relative;
	z-index: 10;

	padding: 0 1em;

	background-color: rgba(255, 255, 255, 1);
	box-shadow: 0 1px 7px #999999;
}

.site-header__bar {
	position: relative;

	min-height: 80px;
}

.site-header__container {
	position: relative;
}

#site-branding {
	position: absolute;
	top: 50%;

	display: block;

	transform: translateY(-50%);
}

#site-logo {
	display: block;
	width: 150px;
}

#site-nav {
	display: block;
	height: 0px;
	overflow: hidden;
}

#site-nav-button {
	position: absolute;
	top: 50%;
	right: 0;

	min-width: 80px;

	transform: translateY(-50%);

	font-weight: bold;
}

#site-nav-links {
	margin: 0;
	padding: 0.25em 0;

	list-style: none;

	text-align: center;
}

#site-nav-links a {
	display: block;
	padding: 0.5em 0.25em;

	border-bottom: 1px solid lighten($color-black, 80%);

	font-size: 1.15em;
	font-weight: bold;
	text-decoration: none;
}

#site-nav-links li:last-child a {
	border-bottom: none;
}

main {
	font-size: 1em;
}

.align-left {
	float: left;
}

.align-center {
	margin: 0 auto;
}

.align-right {
	float: right;
}

#site-footer {
	padding: 2em 1em 3em;

	background-color: $color-primary;

	color: #9b9eac;
	text-align: center;
}

#site-footer a,
#site-footer a:link,
#site-footer a:visited {
	color: #9b9eac;
}

#site-footer a:focus,
#site-footer a:hover,
#site-footer a:active {
	color: #cccccc;
}

#site-footer ul {
	margin: 0;
	margin-bottom: 1em;
	padding: 0;

	list-style: none;
}

#site-footer ul li a {
	display: inline-block;
	padding: 0.25em;
}

#site-footer .site-footer__special-links a {
	min-width: 9em;
	margin: 0.5em;
	padding: 0.5em;

	background: $color-white;

	color: $color-primary;
	font-size: 0.9em;
	font-weight: bold;
	text-decoration: none;
	text-transform: uppercase;
}

#site-footer .site-footer__social-links li {
	display: inline;
}

#site-footer .site-footer__social-links a {
	display: inline-block;
}

.site-footer__address address {
	font-style: normal;
}

.site-footer__copyright {
	font-size: 0.85em;
}

#site-footer .site-footer__address,
#site-footer .site-footer__site-links,
#site-footer .site-footer__social-links,
#site-footer .site-footer__special-links {
	margin-bottom: 1.5em;
}

@media only screen and (min-width: $break-md) {
	#site-footer {
		text-align: left;
	}

	#site-footer .site-footer__social-links {
		text-align: right;
	}

	#site-footer .site-footer__social-links a {
		display: block;
	}
}

@media only screen and (min-width: $break-lg) {
	#site-footer .site-footer__social-links a {
		display: inline-block;
	}
}
