.module {
	padding: 1em;

	background: $color-white;
}

.module--edged {
	padding: 0;
	padding: 0 0 0.01px 0;
}

.module--indented > div {
	@extend .container;
}

.module--limited > div {
	@extend .container;

	max-width: 800px;
}

.module--transparent {
	background-color: transparent;
}

@media only screen and (min-width: $break-md) {
	.module {
		padding-right: $module-padding;
		padding-left: $module-padding;
	}
}
