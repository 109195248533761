/**
 * ********** HTML5BP Clearfix **********
 */

.clearfix::before,
.clearfix::after {
	content: ' ';
	display: table;
}

.clearfix::after {
	clear: both;
}
