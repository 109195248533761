/**
 * ********** Minimal Normalize ********** 
 */

html {
	-ms-text-size-adjust: 100%;     /* IE: Prevent text size adjust after device orientation change. */
	-webkit-text-size-adjust: 100%; /* iOS: Prevent text size adjust after device orientation change. */
}

article,
aside,
figcaption,
figure,
footer,
header,
main,
nav,
section {
	display: block;
}

details,
dialog,
menu,
summary {
	display: block;
}

audio,
canvas,
progress,
video {
	display: inline-block;
}

embed,
iframe,
object,
video {
	max-width: 100%;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

[hidden],
template {
	display: none;
}

svg:not(:root) {
	overflow: hidden;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
	-webkit-border-radius: 0;
	cursor: pointer;
}

button,
input,
optgroup,
select,
textarea {
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

input,
textarea {
	-webkit-border-radius: 0;
	// -webkit-appearance: none;
}

input::-moz-focus-inner {
	border: 0;  /** Button size fix */
	padding: 0; /** Button size fix */
}

[type="text"],
[type="password"],
[type="search"],
[type="email"],
[type="url"],
[type="tel"],
[type="number"] {
	outline: none;
}

textarea {
	vertical-align: top;
	overflow: auto;

	outline: none;
}

fieldset {
	overflow: hidden; /** Older iOS Placeholder Bug */
}

img {
	border: none; /** IE < 11: No border for `img` wrapped with `a`. */
}
