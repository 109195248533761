/**
 * ********** Responsive **********
 */

@media only screen and (min-width: $break-sm) {
	main {
		font-size: 1.125em;
		line-height: 1.625em;
	}
}

@media only screen and (min-width: $break-md) {
	#site-nav-button {
		display: none;
	}

	#site-nav {
		position: absolute;
		top: 50%;
		right: -1em;

		display: block;
		width: auto;
		height: auto !important;

		transform: translateY(-50%);

		font-size: 0.9em;
	}

	#site-nav-links li {
		display: inline-block;
		padding: 0.5em 0.75em;
	}

	#site-nav-links a,
	#site-nav-links a:link,
	#site-nav-links a:visited,
	#site-nav-links li:last-child a,
	#site-nav-links li:last-child a:link,
	#site-nav-links li:last-child a:visited {
		display: inline-block;
		padding: 0.5em 0;

		border-bottom: 4px solid transparent;

		font-weight: bold;
	}

	#site-nav-links a:focus,
	#site-nav-links a:hover,
	#site-nav-links a:active,
	#site-nav-links li:last-child a:focus,
	#site-nav-links li:last-child a:hover,
	#site-nav-links li:last-child a:active {
		border-bottom: 4px solid;
	}

	#site-header,
	#site-footer {
		padding-right: 2.5rem;
		padding-left: 2.5rem;
	}

	main {
		font-size: 1.2em;
		line-height: 1.625em;
	}
}

@media only screen and (min-width: $break-lg) {
	#site-nav {
		font-size: 1em;
	}

	#site-nav-links li {
		padding: 0.5em 1em;
	}
}
