.press-gallery {
	display: flex;
	margin-right: -0.25em;
	margin-left: -0.25em;
}

.press-gallery > div {
	padding: 0.25em;
}

.press-gallery > div img {
	display: block;
	width: 100%;
}
