.module-content {
	padding-top: 2em;
	padding-bottom: 2.5em;
}

.module-content:last-of-type {
	padding-bottom: 3em;
}

.module-content--spaced {
	padding-top: 3.5em;
	padding-bottom: 2.5em;
}
