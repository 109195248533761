.module-hero {
	position: relative;

	display: flex;
	align-items: center;
	min-height: 320px;

	background-position: center bottom;
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-size: cover;
}

.module-hero > div {
	position: relative;

	width: 100%;
	margin-top: 1rem;
	margin-bottom: 3rem;
}

.module-hero::before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	content: '';
	display: block;

	background: rgba(0, 0, 0, 0.5);
}

.module-hero__title,
.module-hero__description {
	// display: inline-block;
	max-width: 100%;

	color: $color-white;
	text-shadow: 1px 1px 10px $color-black;
}

.module-hero__title {
	margin-bottom: 0.5em;

	font-size: 2.25em;
	font-weight: bold;
	line-height: 1.25em;
}

.module-hero__title::before {
	background-color: $color-white;

	text-shadow: 1px 1px 10px $color-black;
}

.module-hero__description {
	margin-bottom: 1.25em;

	font-size: 1.25em;
}

@media only screen and (min-width: $break-md) {
	.module-hero > div {
		margin-top: 3rem;
		margin-bottom: 5rem;
	}

	.module-hero__title,
	.module-hero__description {
		max-width: 60%;
	}

	.text-center .module-hero__title,
	.text-center .module-hero__description {
		width: 100%;
		margin-right: auto;
		margin-left: auto;
	}
}
