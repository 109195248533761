.sponsorship {
	position: relative;

	padding: 2em;
	box-sizing: border-box;

	background: $color-white;

	text-align: center;
}

.sponsorship h2 {
	font-size: 1.75em;
	font-weight: normal;
}

.sponsorship__tag {
	position: absolute;
	top: -20px;
	left: 50%;

	display: block;
	padding: 0.5em 1em;

	background-color: #e89e15;
	transform: translateX(-50%);

	color: $color-white;
}

.sponsorship__tag--pure {
	top: -33px;

	width: 85%;
	padding: 0.25em 0.5em;

	background-color: lighten(#997a6a, 45%);
	border: 5px solid #997a6a;
	border-radius: 15px;

	color: $color-text;
	font-size: 1rem;
	line-height: 1.25;

	img {
		display: block;
		max-height: 30px;
		margin: 0 auto 0.25em;
	}

	a::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		content: '';
		display: block;
	}
}

.sponsorship__special {
	color: $color-price--light;
}

.sponsorship__summary {
	min-height: 175px;
}

.sponsorship__price {
	margin: 2em auto 1.5em;

	color: lighten($color-text--lighter, 10%);
}

.sponsorship-price__value {
	color: $color-price--light;
	font-size: 3em;
}

.sponsorship__billing {
	display: block;
	margin: 1em;

	text-transform: uppercase;
}

.sponsorship__description {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 50px;
	margin: 1em;

	text-align: center;

	img {
		margin: 0.25em auto 0;
	}
}

.sponsorship__action {
	display: block;
	margin: 1em auto;
}

.sponsorship__details {
	margin-top: 2.5em;
	padding: 0;

	list-style: none;

	font-size: 0.9em;
	text-align: left;
}

.sponsorship__details li {
	display: flex;
	padding: 0.5em 0;

	border-bottom: 1px solid lighten($color-black, 80%);
}

.sponsorship__details li:first-child {
	border-top: 1px solid lighten($color-black, 80%);
}

.sponsorship__details li::before {
	content: '\2713';
	display: inline-block;
	padding-right: 0.5em;

	color: $color-positive;
	font-size: 1.15em;
}

@media only screen and (min-width: $break-sm) {
	.sponsorship:nth-child(1) .sponsorship__description,
	.sponsorship:nth-child(2) .sponsorship__description {
		min-height: 200px;
	}
}

@media only screen and (min-width: $break-md) {
	.sponsorship:nth-child(1) .sponsorship__description,
	.sponsorship:nth-child(2) .sponsorship__description {
		min-height: 150px;
	}
}

@media only screen and (min-width: $break-lg) {
	.sponsorship:nth-child(1) .sponsorship__description,
	.sponsorship:nth-child(2) .sponsorship__description {
		min-height: 150px;
	}
}

@media only screen and (min-width: $break-xl) {
	.sponsorship .sponsorship__description,
	.sponsorship:nth-child(1) .sponsorship__description,
	.sponsorship:nth-child(2) .sponsorship__description {
		min-height: 280px;
	}
}
