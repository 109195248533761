/**
 * ********** Grid **********
 */

.container {
	max-width: $width-site;
	margin: 0 auto;
	padding: 0;
}

.grid {
	display: flex;
	flex-wrap: wrap;
	margin-right: -$grid-gutter;
	margin-left: -$grid-gutter;
	padding: 0;
}

.grid > *,
.grid__element {
	flex: 1 1 0;
	width: 100%;
	margin-right: $grid-gutter;
	margin-bottom: $grid-gutter * 2;
	margin-left: $grid-gutter;
}

.grid--row-reverse {
	flex-direction: row-reverse;
}

.grid--no-gutter {
	margin-right: 0;
	margin-left: 0;
}

.grid--no-gutter > *,
.grid--no-gutter .grid__element {
	margin-right: 0;
	margin-bottom: 0;
	margin-left: 0;
}

@for $i from 1 through $grid-columns {
	.grid--#{$i} > *,
	.grid--#{$i} .grid__element {
		flex: 0 1 auto;
		@if $i > 1 {
			width: calc(#{100% / $i} - #{$grid-gutter * 2});
		} @else {
			width: calc(#{100% / $i});
		}
	}
}

@media only screen and (min-width: $break-sm) {
	@for $i from 1 through $grid-columns {
		.grid--sm-#{$i} > *,
		.grid--sm-#{$i} .grid__element {
			flex: 0 1 auto;
			@if $i > 1 {
				width: calc(#{100% / $i} - #{$grid-gutter * 2});
			} @else {
				width: calc(#{100% / $i});
			}
		}
	}
}

@media only screen and (min-width: $break-md) {
	@for $i from 1 through $grid-columns {
		.grid--md-#{$i} > *,
		.grid--md-#{$i} .grid__element {
			flex: 0 1 auto;
			@if $i > 1 {
				width: calc(#{100% / $i} - #{$grid-gutter * 2});
			} @else {
				width: calc(#{100% / $i});
			}
		}
	}
}

@media only screen and (min-width: $break-lg) {
	@for $i from 1 through $grid-columns {
		.grid--lg-#{$i} > *,
		.grid--lg-#{$i} .grid__element {
			flex: 0 1 auto;
			@if $i > 1 {
				width: calc(#{100% / $i} - #{$grid-gutter * 2});
			} @else {
				width: calc(#{100% / $i});
			}
		}
	}
}

@media only screen and (min-width: $break-xl) {
	@for $i from 1 through $grid-columns {
		.grid--xl-#{$i} > *,
		.grid--xl-#{$i} .grid__element {
			flex: 0 1 auto;
			@if $i > 1 {
				width: calc(#{100% / $i} - #{$grid-gutter * 2});
			} @else {
				width: calc(#{100% / $i});
			}
		}
	}
}

.row {
	display: flex;
	flex-wrap: wrap;

	margin-right: -$row-gutter;
	margin-left: -$row-gutter;
}

.row > * {
	width: 100%;
	box-sizing: border-box;

	padding-right: $row-gutter;
	padding-left: $row-gutter;

	margin-bottom: $row-gutter * 2;
}

.row--reverse {
	flex-direction:	row-reverse;
}

.row--no-gutter {
	margin-right: 0;
	margin-left: 0;
}

.row--no-gutter > *,
.col-no-gutter {
	padding-right: 0;
	padding-left: 0;
}

@for $i from 1 through $row-columns {
	.col-#{$i} {
		flex: 0 0 ((100% * $i) / $row-columns);

		max-width: (100% * $i) / $row-columns;
		margin-bottom: 0;
	}
}

@media only screen and (min-width: $break-sm) {
	@for $i from 1 through $row-columns {
		.col-sm-#{$i} {
			flex: 0 0 ((100% * $i) / $row-columns);

			max-width: (100% * $i) / $row-columns;
			margin-bottom: 0;
		}
	}
}

@media only screen and (min-width: $break-md) {
	@for $i from 1 through $row-columns {
		.col-md-#{$i} {
			flex: 0 0 ((100% * $i) / $row-columns);

			max-width: (100% * $i) / $row-columns;
			margin-bottom: 0;
		}
	}
}

@media only screen and (min-width: $break-lg) {
	@for $i from 1 through $row-columns {
		.col-lg-#{$i} {
			flex: 0 0 ((100% * $i) / $row-columns);

			max-width: (100% * $i) / $row-columns;
			margin-bottom: 0;
		}
	}
}

@media only screen and (min-width: $break-xl) {
	@for $i from 1 through $row-columns {
		.col-xl-#{$i} {
			flex: 0 0 ((100% * $i) / $row-columns);

			max-width: (100% * $i) / $row-columns;;
			margin-bottom: 0;
		}
	}
}
