.module-title {
	position: relative;

	min-height: 250px;
	padding: 1.5em 1em 3em;

	background: url('/res/img/flock-sun.jpg') center 20% no-repeat scroll;
	background-size: cover;
}

.module-title > div {
	position: relative;
}

.module-title h1 {
	max-width: 8.75em;
	margin: 0 auto;
	margin-bottom: 0.5em;

	color: $color-white;
	font-size: 3.25em;
	text-shadow: 1px 1px 10px $color-black;
}

.module-title h1::before {
	background-color: $color-white;
	box-shadow: 1px 1px 10px $color-black;
}

.module-title h1 span {
	display: block;
	margin: -1.25em 0 -0.75em;

	font-size: 0.35em;
}

.module-title .btn--special {
	display: block;
	max-width: 320px;
	margin: 1em auto;
}

.module-title__more-link,
.module-title__more-link:link,
.module-title__more-link:visited {
	display: block;
	margin: 1.25em auto 1em;

	color: $color-white;
	font-size: 1.2em;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.module-title__more-link::after {
	content: '\2193';
	display: block;
}

@media only screen and (min-width: $break-md) {
	.module-title h1 {
		font-size: 4.5em;
	}

	.module-title h1 span {
		margin: -1.75em 0 -1.25em;
	}
}
