/**
 * ********** Print styles **********
 */

/** HTML5BP */
@media print {
	*,
	*:before,
	*:after,
	p:first-letter,
	div:first-letter,
	blockquote:first-letter,
	li:first-letter,
	p:first-line,
	div:first-line,
	blockquote:first-line,
	li:first-line {
		background: transparent !important;
		box-shadow: none !important;

		// color: #000 !important;
		text-shadow: none !important;
	}

	a,
	a:visited {
		text-decoration: underline;
	}

	a[href]:after {
		content: " (" attr(href) ")";
	}

	abbr[title]:after {
		content: " (" attr(title) ")";
	}

	a[href^="#"]:after,
	a[href^="javascript:"]:after {
		content: "";
	}

	pre {
		white-space: pre-wrap !important;
	}

	pre,
	blockquote {
		border: 1px solid #999;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group;
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	img {
		max-width: 100% !important;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}
}

/** Custom */
@media print {
	//
}
